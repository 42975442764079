import React, { useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { api } from '../../services/api';
import { navigate } from 'gatsby';
import { isMobile } from 'react-device-detect';
import MobileNotSupport from '../../components/MobileNotSupport';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Backdrop, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        width: theme.spacing(8),
        height: theme.spacing(8),
        backgroundColor: theme.palette.secondary.light,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const forgotPasswordPage = () => {
    const emailRegrex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const classes = useStyles();
    const [openLoading, setOpenLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/sign-in');
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (email === '') {
            setEmailError('This field is required');
        } else if (!emailRegrex.test(email)) {
            setEmailError('Please input a valid email');
        } else {
            setEmailError('');
        }

        if (emailError === '') {
            setOpenLoading(true);

            api({
                method: 'post',
                url: `/auth/resend-password`,
                data: {
                    email: email
                }
            }).then(response => {
                setOpenLoading(false);

                if ('success' in response.data && response.data.success === true) {
                    setOpenDialog(true);
                } else {
                    setEmailError(response.data.message);
                }
            }).catch(error => {
                console.log(error);
                setOpenLoading(false);
                setEmailError('An error ocurred. Please try again.')
            });
        }
    }

    if (isMobile) {
        return (
            <MobileNotSupport />
        )
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />

            <Backdrop className={classes.backdrop} open={openLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog open={openDialog} 
                onClose={handleCloseDialog} 
                disableBackdropClick 
                aria-describedby="alert-dialog-description">
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Password has been resent to your mailbox.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary">
                        OK
                    </Button>
                    </DialogActions>
            </Dialog>

            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" align="center">
                    Enter your email and we'll resend your password
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit} >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoFocus
                        value={email}
                        onInput={e => setEmail(e.target.value)}
                        error={emailError !== '' ? true : false}
                        helperText={emailError}
                    />
                    <Link color="inherit" href="/sign-in">
                       Sign in
                    </Link>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Resend password
                    </Button>
                </form>
            </div>
        </Container>
    );
}

export default forgotPasswordPage;